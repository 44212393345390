import React from 'react'

function dateFormat(props) {

    const { createdDate } = props
    const data = createdDate;
    const dataObject = new Date(data);
    const day = dataObject.getDate();
    const month = dataObject.getMonth() + 1;
    const year = dataObject.getFullYear();
    const formatdate = `${day}/${month}/${year}`;

    return (
        <>
        {formatdate}
        </>
    )
}

export default dateFormat
